import * as React from "react"
import {Box, Button, Grid, Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import {StaticImage} from "gatsby-plugin-image";
import Container from "@mui/material/Container";
import {sectionPadding, textMaxWidth} from "../../theme";
import {graphql, Link} from "gatsby";
import {Bouwnummer, Bouwtype} from "../../graphqlTypes";
import GevelaanzichtBlok1 from "../../components/gevelaanzichten/blok1";
import GevelaanzichtBlok5 from "../../components/gevelaanzichten/blok5";
import Situatie from "../../components/situatie";
import BouwtypeHeader from "../../components/bouwtype/header";
import Plattegronden from "../../components/bouwtype/plattegronden";
import Fotos from "../../components/bouwtype/fotos";
import GevelaanzichtBlok2En3 from "../../components/gevelaanzichten/blok2-3";
import Oppervlakte from "../../components/oppervlakte";

interface Props {
    data: PageQueryData
}
export default ({data}: Props) => {
    return (
        <article>
            <BouwtypeHeader
                title={'Blok 2 & 3'}
                bouwtypes={data.allBouwtype.nodes}
                bouwnummers={data.allBouwnummer.nodes}
            />
            <Box
                sx={{
                    paddingTop: {xs: sectionPadding, md: 0},
                    paddingBottom: sectionPadding
                }}
                paddingY={sectionPadding}
            >
                <Container>
                    <Box
                        maxWidth={textMaxWidth}
                    >
                        <Typography
                            paragraph
                            color={'primary.main'}
                            fontWeight={'medium'}
                        >
                            Blok 2 en 3 zijn beide twee-onder-één-kapwoningen en zijn levensloopbestendig. Het woonoppervlak varieert van 104 tot en met 118 m2. De bouwnummers 4 en 7 beschikken over een topgevel. Alle woningen beschikken over een eigen parkeerplaats.
                        </Typography>
                        <Typography
                            paragraph
                            color={'primary.main'}
                            fontWeight={'medium'}
                        >
                            De verscheidenheid in het aangezicht geven de woningen karakter. Achter zorgen dubbele openslaande deuren voor een vrij gevoel naar buiten. Bouwnummers 4, 5 en 6 hebben een tuin gericht op het oosten. De tuin van bouwnummer 7 is gesitueerd op het zuiden.
                        </Typography>
                    </Box>
                </Container>
            </Box>
            <Box>
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8}>
                            <StaticImage src={'../../images/impressies/Interieur-BNR-4.jpg'} alt={'interieur bouwnummer 4'}/>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Paper
                                sx={{
                                    backgroundColor: 'secondary.main',
                                    padding: 4,
                                    height: '100%'
                                }}

                            >
                                <Typography
                                    variant={'h4'}
                                    color={'common.white'}
                                >
                                    Kenmerken
                                </Typography>
                                <Typography
                                    color={'common.white'}
                                >
                                    <Box
                                        component={'ul'}
                                    >
                                        <li>
                                            Mogelijkheid tot slapen op de begane grond
                                        </li>
                                        <li>
                                            Beukmaat 6.00m
                                        </li>
                                        <li>
                                            Woonoppervlakte variërend van <Oppervlakte oppervlakte={'104 tot 118'}/>
                                        </li>
                                        <li>
                                            Perceel variërend van <Oppervlakte oppervlakte={'187 tot 207'} />
                                        </li>
                                        <li>
                                            Ruime woonkamer en lichte, open keuken
                                        </li>
                                        <li>
                                            Bergzolder op de tweede verdieping
                                        </li>
                                        <li>
                                            Dubbele openslaande deuren naar de tuin
                                        </li>
                                        <li>
                                            2 (slaap-)kamers en badkamer op de eerste verdieping
                                        </li>
                                        <li>
                                            Bouwnummers 4, 5 en 6 hebben een aangebouwde berging
                                        </li>
                                        <li>
                                            Tuin inclusief eigen berging
                                        </li>
                                        <li>
                                            Voorzien van warmtepomp en zonnepanelen
                                        </li>
                                    </Box>
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Plattegronden bouwtypes={data.allBouwtype.nodes} />
            <Box>
                <Container>
                    <Box
                        sx={{
                            paddingY: sectionPadding*2,
                            backgroundColor: 'secondary.light',
                        }}
                        textAlign={'center'}
                    >
                        <Box
                            maxWidth={textMaxWidth}
                            marginX={'auto'}
                        >
                            <Typography variant={'h2'}>
                                Optimaal woongenot
                            </Typography>
                            <Typography paragraph>
                                Welkom! Stap binnen in een echt comforthuis. Met de keuken aan de voorzijde van de woning is er veel betrokkenheid met de omgeving. De woonkamer grenst aan de ruim bemeten tuin en biedt veel licht dankzij de dubbele openslaande deuren. De ideale plek om in een boek te verdwijnen of heerlijk te ontspannen voor de televisie.
                            </Typography>
                            <Typography paragraph>
                                De mogelijkheid bestaat voor een slaap- en badkamer op de begane grond, maar in de basis heeft u al twee slaapkamers, een comfortabele badkamer en een wasruimte op de verdieping. Via de vlizotrap is er nog een ruime bergzolder bereikbaar. Dit is nog eens onbezorgd genieten!
                            </Typography>
                        </Box>
                    </Box>
                </Container>
            </Box>
            <Fotos bouwtypes={[data.allBouwtype.nodes[0]]} />
            <Box
                paddingY={sectionPadding}
            >
                <Container>
                    <Typography
                        variant={'h2'}
                        textAlign={'center'}
                    >
                        Gevelaanzichten
                    </Typography>
                    <GevelaanzichtBlok2En3 />
                </Container>
            </Box>
            <Box
                paddingY={sectionPadding}
            >
                <Typography
                    variant={'h2'}
                    textAlign={'center'}
                >
                    Situatie Daverhof
                </Typography>
                <Container>
                    <Situatie bouwnummers={data.allBouwnummer.nodes.filter(bouwnummer => bouwnummer.fields.coordinaten)} />
                </Container>
            </Box>
            <Box
                paddingY={sectionPadding}
                textAlign={'center'}
            >
                <Typography
                    variant={'h2'}
                    textAlign={'center'}
                >
                    Bekijk meer woningen
                </Typography>
                <Button
                    component={Link}
                    to={'/woningen/blok1-en-5'}
                    variant={'contained'}
                    sx={{
                        marginX: 1
                    }}
                >
                    Blok 1 + 5
                </Button>
                <Button
                    component={Link}
                    to={'/woningen/blok4-en-6'}
                    variant={'contained'}
                    sx={{
                        marginX: 1
                    }}
                >
                    Blok 4 + 6
                </Button>
            </Box>
        </article>
    )
}

interface PageQueryData {
    allBouwnummer: {
        nodes: Bouwnummer[]
    }
    allBouwtype: {
        nodes: Bouwtype[]
    }
}

export const query = graphql`
    query {
        allBouwnummer(filter: {bouwtype: {algemeen: {omschrijving: {in: ["Blok 2", "Blok 3"]}}}}) {
            nodes {
                id
                ...BouwnummerArea
            }
        }
        allBouwtype(filter: {algemeen: {omschrijving: {in: ["Blok 2", "Blok 3"]}}}) {
            nodes {
                id
                ...BouwtypeHeader
                ...BouwtypePlattegronden
                ...BouwtypeSpecificaties
            }
        }
    }
`
